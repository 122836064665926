import type { FunctionalComponent } from 'preact';

import { BlockWrapper } from 'src/shared/components/block-wrapper/block-wrapper.js';
import { Button } from 'src/shared/components/button/button.js';
import { MultilineText } from 'src/shared/components/multiline-text/multiline-text.js';
import { ResponsiveImage } from 'src/shared/components/responsive-image/responsive-image.js';
import type { Image } from 'src/shared/types/image.js';

import { BlockProfession1Content } from './__content/block-profession-1__content.js';
import { BlockProfession1Difficulty } from './__difficulty/block-profession-1__difficulty.js';
import { BlockProfession1Head } from './__head/block-profession-1__head.js';
import { cnBlockProfession1 } from './block-profession-1.constants.js';

import './block-profession-1.css';

import './__button/block-profession-1__button.css';
import './__description/block-profession-1__description.css';
import './__duration/block-profession-1__duration.css';
import './__image/block-profession-1__image.css';
import './__main/block-profession-1__main.css';
import './__profession/block-profession-1__profession.css';
import './__start-date/block-profession-1__start-date.css';
import './__text/block-profession-1__text.css';
import './__title/block-profession-1__title.css';

type BlockAttributes = {
    pretitle: string;
    difficulty: '1' | '2' | '3' | '4' | '5';
    profession: string;
    duration: string;
    cohortDate: string | null;
    buttonText: string;
    buttonLink: string;
    image: Image;
    title1: string;
    text1: string;
    title2: string;
    text2: string;
    title3: string;
    nextGroupStartsSoonText: string;
    joinBeforeStartDateText: string;
};

export interface BlockProfession1Props extends BlockAttributes {}

export const BlockProfession1: FunctionalComponent<BlockProfession1Props> = ({
    pretitle,
    difficulty,
    profession,
    duration,
    cohortDate,
    buttonText,
    buttonLink,
    image,
    title1,
    text1,
    title2,
    text2,
    title3,
    nextGroupStartsSoonText,
    joinBeforeStartDateText,
}) => (
    <BlockWrapper className={cnBlockProfession1()}>
        <div className={cnBlockProfession1('main')}>
            <BlockProfession1Head>
                <BlockProfession1Difficulty pretitle={pretitle} difficulty={difficulty} />
                <MultilineText className={cnBlockProfession1('profession')}>{profession}</MultilineText>
            </BlockProfession1Head>
            <BlockProfession1Content>
                <MultilineText className={cnBlockProfession1('duration')}>{duration}</MultilineText>
                <MultilineText className={cnBlockProfession1('start-date')}>
                    {cohortDate ? `${joinBeforeStartDateText}\n${cohortDate}` : nextGroupStartsSoonText}
                </MultilineText>
            </BlockProfession1Content>
            <Button className={cnBlockProfession1('button')} href={buttonLink} mode="dark">
                {buttonText}
            </Button>
        </div>
        <ResponsiveImage className={cnBlockProfession1('image')} image={image} />
        <div className={cnBlockProfession1('description')}>
            <MultilineText className={cnBlockProfession1('title')}>{title1}</MultilineText>
            <MultilineText className={cnBlockProfession1('text')}>{text1}</MultilineText>
            <MultilineText className={cnBlockProfession1('title')}>{title2}</MultilineText>
            <MultilineText className={cnBlockProfession1('text')}>{text2}</MultilineText>
            <MultilineText className={cnBlockProfession1('title')}>{title3}</MultilineText>
        </div>
    </BlockWrapper>
);
