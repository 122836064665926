import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockProfession1 } from '../block-profession-1.constants.js';

export interface BlockProfession1ContentProps {
    children: ComponentChildren;
}

export const BlockProfession1Content: FunctionalComponent<BlockProfession1ContentProps> = ({ children }) => (
    <div className={cnBlockProfession1('content')}>{children}</div>
);
