import type { FunctionalComponent } from 'preact';

import { MultilineText } from 'src/shared/components/multiline-text/multiline-text.js';

import { cnBlockProfession1 } from '../block-profession-1.constants.js';

import './block-profession-1__difficulty.css';

import './_active/block-profession-1__difficulty_active.css';

export interface BlockProfession1DifficultyProps {
    pretitle: string;
    difficulty: '1' | '2' | '3' | '4' | '5';
}

export const BlockProfession1Difficulty: FunctionalComponent<BlockProfession1DifficultyProps> = ({
    pretitle,
    difficulty,
}) => (
    <div className={cnBlockProfession1('difficulty')}>
        <MultilineText className={cnBlockProfession1('difficulty-text')}>{pretitle}</MultilineText>
        <div className={cnBlockProfession1('difficulty-box')}>
            {new Array(Number(5)).fill('').map((_, index) => (
                <div
                    key={index}
                    className={cnBlockProfession1('difficulty-box-item', { active: index + 1 <= Number(difficulty) })}
                />
            ))}
        </div>
    </div>
);
