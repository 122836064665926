import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockProfession1 } from '../block-profession-1.constants.js';

export interface BlockProfession1HeadProps {
    children: ComponentChildren;
}

export const BlockProfession1Head: FunctionalComponent<BlockProfession1HeadProps> = ({ children }) => (
    <div className={cnBlockProfession1('head')}>{children}</div>
);
